import { createStore, combineReducers } from 'redux'

import { ActionDocument, Sync, uuid, syncRedux } from 'action-document'
import * as todoReducerFunctions from './todos'
import settingsReducer from './reducers/settingsReducer'

import config from './config.js'

const originId = uuid() // new session on reload

// use documentId provided in URL
const matches = window.location.hash.match(/[0-9a-f]{24}/gim) || null
const documentId = matches ? matches[0] : null
const doc = new ActionDocument(todoReducerFunctions, {
  documentId,
  originId,
  type: 'todos'
})

const sync = new Sync(config.sync)
sync.registerDocument(doc)

if (!documentId) {
  window.location.hash = `#${doc.documentId}`
}

const reducer = combineReducers({
  todos: doc.getReducer(),
  history: doc.getHistoryReducer(),
  sync: syncRedux.reducer,
  originId: originId,
  settings: settingsReducer
})

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

doc.connectStore(store)
syncRedux.connectStore(sync, store)

const actions = doc.actions
const coreActions = doc.coreActions

export { doc, store, actions, coreActions, sync }
