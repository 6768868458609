const reducer = (state = { showMonitor: false }, action) => {
  switch (action.type) {
    case 'SET_SHOW_MONITOR':
      return {
        ...state,
        showMonitor: action.value
      }
    default:
      return state
  }
}

export default reducer
