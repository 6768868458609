import React from 'react'
import { crc24 } from 'crc'

const originColors = {}
const colorForOrigin = (originId) => {
  if (!originColors[originId]) {
    originColors[originId] = '#' + crc24(String(originId)).toString(16)
  }
  return originColors[originId]
}

const MonitorItem = ({ item, index, originId, actions }) => {
  const { type, ...params } = item.payload

  const className =
    'MonitorItem ' + (!item.active ? ' MonitorItem--inactive' : '')

  return (
    <li className={className} title={item.id}>
      <div className="MonitorItem__body">
        <div className="MonitorItem__info">
          {index + 1}. {type}
        </div>
        <pre>{JSON.stringify(params, null, 2)}</pre>
        <div
          className="MonitorItem__origin"
          style={{ background: colorForOrigin(item.originId) }}
        />
      </div>
    </li>
  )
}

export default MonitorItem
