export const init = () => []

export const add = (state, id, text) => [
  ...state,
  {
    id,
    text,
    checked: false
  }
]

export const check = (state, id, value) => {
  const index = state.findIndex(item => item.id === id)
  return index < 0
    ? state
    : [
        ...state.slice(0, index),
        {
          ...state[index],
          checked: value
        },
        ...state.slice(index + 1)
      ]
}

export const remove = (state, id) => state.filter(item => item.id !== id)
