import React, { Component } from 'react'
import { connect } from 'react-redux'

import { coreActions } from '../app'

class UndoRedo extends Component {
  constructor(props, context) {
    super(props)
    this.handleKeydown = this.handleKeydown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown)
  }

  handleKeydown(e) {
    const { canUndo, canRedo, onUndo, onRedo } = this.props
    if (!e.ctrlKey && !e.metaKey) {
      return
    }

    if (e.keyCode === 90) {
      e.preventDefault()
      if (canUndo) onUndo()
    } else if (e.keyCode === 89 || (e.shiftKey && e.keyCode === 90)) {
      e.preventDefault()
      if (canRedo) onRedo()
    }
  }

  render() {
    const { canUndo, canRedo, onUndo, onRedo } = this.props

    return (
      <span className="UndoRedo">
        <button onClick={() => onUndo()} disabled={!canUndo} title="Undo">
          <span className="icon-undo" />
        </button>
        <button onClick={() => onRedo()} disabled={!canRedo} title="Redo">
          <span className="icon-redo" />
        </button>
      </span>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  canUndo: state.history.canUndo,
  canRedo: state.history.canRedo
})

const mapDispatchToProps = dispatch => {
  return {
    onUndo: () => dispatch(coreActions.undo()),
    onRedo: () => dispatch(coreActions.redo())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo)
