import React from 'react'

import './ConnectionStatus.css'

const ConnectionStatus = ({ status, enable, disable }) => {
  const buttonProperties = {
    DISCONNECTED: {
      className: 'ConnectionStatus disconnected',
      onClick: e => enable(),
      title: 'Connect to server'
    },
    CONNECTING: {
      className: 'ConnectionStatus connecting',
      disabled: true,
      title: 'Connecting…'
    },
    CONNECTED: {
      className: 'ConnectionStatus connected',
      onClick: e => disable(),
      title: 'Go offline'
    }
  }[status]

  return (
    <button {...buttonProperties}>
      <span className={`icon icon-cloud ${status}`} />
    </button>
  )
}

export default ConnectionStatus
