import React from 'react'

import Toolbar from './Toolbar'
import Todos from './Todos'
import Monitor from './Monitor'
import './App.css'
import { store, actions, doc } from '../app'

const App = () =>
  <div className="App">
    <div>
      <Toolbar />
      <Todos todos={store} actions={actions} />
    </div>
    <Monitor doc={doc} />
  </div>


export default App
