import React from 'react'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'

import { store } from './app'
import App from './components/App'

import './index.css'
import './fonts.css'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)
