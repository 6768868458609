import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { uuid } from 'action-document'
import { actions } from '../app'
import './Todos.css'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

class Todos extends Component {
  constructor() {
    super()
    this.state = {
      itemAdded: false,
    }
  }

  componentDidMount() {
    !isMobile && this.focusInput()
  }

  componentDidUpdate() {
    if (this.state.itemAdded) {
      this.setState({ itemAdded: false })
      setTimeout(() => this._input.focus(), 100)
      this.scrollToBottom()
    } else {
      this.focusInput()
    }
  }

  focusInput() {
    if (this.props.todos.length) {
      return
    }
    setTimeout(() => {
      this._input.focus()
    }, 100)
  }

  addTodo() {
    if (this._input.value === '') {
      this._input.focus()
      return
    }
    this.props.add(uuid(), this._input.value)
    this._input.value = ''
    if (!isMobile) {
      this.setState({ itemAdded: true })
    }
  }

  handleFocus() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    this._viewBottom && this._viewBottom.scrollIntoView()
  }

  render() {
    const { todos, check, remove } = this.props
    return (
      <div className="Todos">
        {todos.length ? (
          <div
            className={classNames('Todos__List', {
              'Todos-List--with-monitor': this.props.showMonitor,
            })}
          >
            <ul>
              {todos.map((todo) => {
                const className = todo.checked ? 'checked' : ''
                const props = {
                  onClick: (e) => check(todo.id, !todo.checked),
                }
                return (
                  <li key={todo.id} className={className}>
                    {todo.checked ? (
                      <span {...props} className="icon-checkbox-checked" />
                    ) : (
                      <span {...props} className="icon-checkbox-unchecked" />
                    )}
                    <div {...props}>{todo.text}</div>
                    <button onClick={(e) => remove(todo.id)} />
                  </li>
                )
              })}
            </ul>
            <div ref={(e) => (this._listBottom = e)} />
          </div>
        ) : (
          <span>{'nothing to do'}</span>
        )}
        <div className="Todos__InputArea">
          <input
            type="text"
            ref={(e) => (this._input = e)}
            onKeyDown={(e) => e.keyCode === 13 && this.addTodo()}
            onFocus={(e) => this.handleFocus()}
          />
          <button
            onClick={(e) => {
              e.preventDefault()
              this.addTodo()
            }}
          >
            Add
          </button>
        </div>
        <div ref={(e) => (this._viewBottom = e)} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  todos: state.todos,
  showMonitor: state.settings.showMonitor,
})

export default connect(mapStateToProps, actions)(Todos)
