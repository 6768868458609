import React from 'react'
import { connect } from 'react-redux'

import ConnectionStatus from './ConnectionStatus'
import UndoRedo from './UndoRedo'

import { sync } from '../app'

import './Toolbar.css'

const Toolbar = ({ connectionStatus, showMonitor, setShowMonitor }) => (
  <div className="Toolbar">
    <ConnectionStatus
      status={connectionStatus}
      enable={() => {
        sync.enable()
      }}
      disable={() => {
        sync.disable()
      }}
    />
    <UndoRedo />
    <a
      href={window.location.href}
      title="Share link"
      onClick={(e) => window.open(window.location.href)}
    >
      <span className="icon-share2" />
    </a>
    <button
      title="Toggle actions"
      className={showMonitor ? 'open' : ''}
      onClick={(e) => setShowMonitor(!showMonitor)}
    >
      <span className="icon-clock" />
    </button>
  </div>
)

const mapStateToProps = (state, ownProps) => ({
  connectionStatus: state.sync.connectionStatus,
  showMonitor: state.settings.showMonitor,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setShowMonitor: (value) =>
      dispatch({
        type: 'SET_SHOW_MONITOR',
        value,
      }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
