import EventEmitter from 'eventemitter3'

const debug = false

const reconnectAfter = 1000

const status = {
  DISCONNECTED: 'DISCONNECTED',
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
}

const eventNames = {
  UPDATE_CONNECTION_STATUS: 'UPDATE_CONNECTION_STATUS',
  ACCESS_DENIED: 'ACCESS_DENIED',
  NOT_FOUND: 'NOT_FOUND',
}

const socketActions = {
  CLIENT_REQUESTS_DOCUMENT: 'CLIENT_REQUESTS_DOCUMENT',
  CLIENT_SENDS_ACTIONS: 'CLIENT_SENDS_ACTIONS',
  SERVER_SENDS_ACTIONS: 'SERVER_SENDS_ACTIONS',
  SERVER_ACCESS_DENIED: 'SERVER_ACCESS_DENIED',
  SERVER_NOT_FOUND: 'SERVER_NOT_FOUND',
}

export default class Sync extends EventEmitter {
  constructor(url) {
    super()
    this.url = url
    this.connectionStatus = status.DISCONNECTED
    this.isEnabled = true
    this.documents = []
    this.sentActionIds = {}
    this.socket = null
    this.outbox = []
    this.connect()
  }

  enable() {
    this.isEnabled = true
    this.connect()
  }

  disable() {
    this.isEnabled = false
    this.socket.close()
  }

  registerDocument(document, readOnly = false) {
    const { documentId } = document

    if (!readOnly) {
      document.on('local-update', () => {
        this.sendNewActions(document)
      })
    }

    this.documents[documentId] = {
      document,
      readOnly,
    }

    this.requestDocument(document)
  }

  requestDocument(document) {
    const { documentId, type, readOnly } = document
    this.send(socketActions.CLIENT_REQUESTS_DOCUMENT, {
      documentId,
      type,
      readOnly,
    })
  }

  sendNewActions(document) {
    const newIds = Object.keys(document.history).filter(
      (id) => !this.sentActionIds[id]
    )
    this.send(socketActions.CLIENT_SENDS_ACTIONS, {
      documentId: document.documentId,
      type: document.type,
      actions: newIds.map((id) => {
        this.sentActionIds[id] = true
        return document.history[id]
      }),
    })
  }

  onReceiveActions(actions) {
    const groupedActions = {}
    actions
      .filter(
        (action) =>
          this.documents[action.documentId] &&
          this.documents[action.documentId].document
      )
      .forEach((action) => {
        if (!groupedActions[action.documentId]) {
          groupedActions[action.documentId] = []
        }
        groupedActions[action.documentId].push(action)
      })
    Object.entries(groupedActions).forEach(([documentId, actions]) => {
      this.documents[documentId].document.addWrappedActions(actions)
    })
  }

  onConnected() {
    Object.keys(this.documents).forEach((key) => {
      const doc = this.documents[key].document
      this.requestDocument(doc)
    })
  }

  send(name, payload) {
    this.outbox.push({ name, payload, clientTimestamp: Date.now() })
    if (this.connectionStatus === status.CONNECTED) {
      this.processOutbox()
    }
  }

  processOutbox() {
    Object.keys(this.outbox).forEach(() =>
      this.socket.send(JSON.stringify(this.outbox.shift()))
    )
  }

  connect() {
    if (!this.isEnabled) {
      return
    }

    this.socket = new WebSocket(this.url)

    this.updateConnectionStatus(status.CONNECTING)

    this.socket.onopen = () => {
      this.updateConnectionStatus(status.CONNECTED)
      this.onConnected()
      this.processOutbox()
    }

    this.socket.onmessage = (e) => {
      let data = null
      try {
        data = JSON.parse(e.data)
      } catch (e) {
        this.log('error parsing message data', data)
      }
      this.handleSocketAction(data.name, data.payload)
    }

    this.socket.onclose = () => {
      this.updateConnectionStatus(
        this.isEnabled ? status.CONNECTING : status.DISCONNECTED
      )

      if (this.isEnabled) {
        window.clearTimeout(this.reconnectTimer)
        this.reconnectTimer = window.setTimeout(() => {
          this.connect()
        }, reconnectAfter)
      }
    }
  }

  handleSocketAction(name, data) {
    switch (name) {
      case socketActions.SERVER_SENDS_ACTIONS:
        this.onReceiveActions(data)
        break
      case socketActions.SERVER_NOT_FOUND:
        this.emit(eventNames.NOT_FOUND, data.documentId)
        break
      case socketActions.SERVER_ACCESS_DENIED:
        this.emit(eventNames.ACCESS_DENIED, data.documentId)
        break
      default:
    }
  }

  updateConnectionStatus(status) {
    if (status !== this.connectionStatus) {
      this.connectionStatus = status
      this.emit(eventNames.UPDATE_CONNECTION_STATUS, status)
    }
  }

  log() {
    if (debug) {
      console.log.apply(console, arguments)
    }
  }
}
