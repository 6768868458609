import React, { Component } from 'react'
import { connect } from 'react-redux'

import MonitorItem from './MonitorItem'
import { actions as todoActions } from '../app'

import './Monitor.css'

class Monitor extends Component {
  constructor() {
    super()
    this.state = {
      items: [],
      showActivation: false
    }
    this.references = {}
  }

  componentDidMount() {
    ;['local-update', 'remote-update'].forEach(eventName => {
      this.references[eventName] = this.props.doc.on(eventName, () => {
        this.setState({
          items: this.props.doc.getChronologicalStack()
        })
      })
    })
  }

  componentDidUpdate() {
    if (this.listWrapper) {
      this.listWrapper.scrollTop = 0
    }
  }

  componentWillUnmount() {
    Object.keys(this.references).forEach(eventName =>
      this.props.doc.removeListener(eventName, this.references[eventName])
    )
  }

  render() {
    const ShowActivationToggle = this.state.showActivation
      ? <button onClick={() => this.setState({ showActivation: false })}>
          Hide undo/redo
        </button>
      : <button onClick={() => this.setState({ showActivation: true })}>
          Show undo/redo
        </button>

    const actions = this.state.items
      .filter(
        action =>
          this.state.showActivation ||
          !['@ACTIVATE', '@DEACTIVATE'].includes(action.payload.type)
      )
      .reverse()

    let index = actions.length

    return !this.props.showMonitor
      ? null
      : <div className="Monitor">
          <div className="Monitor__Wrapper">
            <div className="MonitorToolbar">
              <h3>Actions</h3>
              {ShowActivationToggle}
            </div>
            <div
              className="MonitorListWrapper"
              ref={e => (this.listWrapper = e)}
            >
              {!actions.length
                ? <span className="MonitorListEmpty">no actions</span>
                : <ul>
                    {actions.map(item => {
                      index--
                      return (
                        <MonitorItem
                          actions={todoActions}
                          key={item.id}
                          index={index}
                          item={item}
                          originId={item.originId}
                        />
                      )
                    })}
                  </ul>}
            </div>
          </div>
        </div>
  }
}

const mapStateToProps = (state, ownProps) => ({
  originId: state.originId,
  showMonitor: state.settings.showMonitor
})

export default connect(mapStateToProps, todoActions)(Monitor)
