const actions = {
  updateConnectionStatus: connectionStatus => ({
    type: 'UPDATE_CONNECTION_STATUS',
    connectionStatus
  })
}

export default {
  reducer: (state = {}, action) => {
    switch (action.type) {
      case 'UPDATE_CONNECTION_STATUS':
        return {
          connectionStatus: action.connectionStatus
        }
      default:
        return state
    }
  },

  connectStore: (sync, store) => {
    sync.on('UPDATE_CONNECTION_STATUS', status =>
      store.dispatch(actions.updateConnectionStatus(status))
    )
  }
}
