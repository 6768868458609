import EventEmitter from 'eventemitter3'
import getParameterNames from 'get-parameter-names'
import uuid from './uuid'

const actionTypes = {
  ACTIVATE: '@ACTIVATE',
  DEACTIVATE: '@DEACTIVATE',
  EXTERNAL_UPDATE: '@EXTERNAL_UPDATE',
  NOP: '@NOP',
  REDO: '@REDO',
  UNDO: '@UNDO',
}

export default class ActionDocument extends EventEmitter {
  constructor(
    reducerFunctions = {},
    {
      documentId,
      originId,
      undoRedoBoundToOrigin = true,
      type = null,
      getReducerArguments = null,
    } = {}
  ) {
    super()

    this.reducerFunctions = reducerFunctions
    this.documentId = documentId || uuid()
    this.originId = originId || uuid()
    this.undoRedoBoundToOrigin = undoRedoBoundToOrigin
    this.type = type
    this.getReducerArguments = getReducerArguments || (() => [])

    // extract parameter names from reducer functions
    this.parameterNames = Object.keys(reducerFunctions).reduce(
      (prev, name) => ({
        ...prev,
        [name]: getParameterNames(reducerFunctions[name]).slice(1),
      }),
      {}
    )

    this.actions = this.getActions()
    this.coreActions = this.getCoreActions()

    this.initialState =
      typeof reducerFunctions.init === 'function' ? reducerFunctions.init() : {}
    this.history = {}
    this.actionIndex = 0
  }

  connectStore(store) {
    this.on('remote-update', () => {
      // clumsily replace with state rendered by ActionDocument
      store.dispatch({
        type: actionTypes.EXTERNAL_UPDATE,
        _documentId: this.documentId,
      })
    })
  }

  getCoreActions() {
    return {
      undo: () => {
        const id = this.findUndoActionId()
        return id
          ? {
              type: actionTypes.DEACTIVATE,
              id,
            }
          : { type: actionTypes.NOP }
      },

      redo: () => {
        const id = this.findRedoActionId()
        return id
          ? {
              type: actionTypes.ACTIVATE,
              id,
            }
          : { type: actionTypes.NOP }
      },
    }
  }

  getActions() {
    return Object.keys(this.parameterNames).reduce(
      (previous, key) => ({
        ...previous,
        [key]: (...args) =>
          this.parameterNames[key].reduce(
            (prev, name) => ({
              ...prev,
              [name]: args[this.parameterNames[key].indexOf(name)],
            }),
            { type: key }
          ),
      }),
      {}
    )
  }

  getUndoRedoStack() {
    return this.getChronologicalStack()
      .filter(
        (action) =>
          !this.undoRedoBoundToOrigin || action.originId === this.originId
      )
      .reverse()
  }

  findUndoActionId(stack = this.getUndoRedoStack()) {
    const undoableAction = stack.find(
      (action) =>
        action.active &&
        ![actionTypes.ACTIVATE, actionTypes.DEACTIVATE].includes(
          action.payload.type
        )
    )
    return undoableAction ? undoableAction.id : null
  }

  findRedoActionId(stack = this.getUndoRedoStack()) {
    for (let action of stack) {
      if (
        ![actionTypes.ACTIVATE, actionTypes.DEACTIVATE].includes(
          action.payload.type
        )
      ) {
        // redo stack ends at any regular action
        return null
      }
      if (
        action.payload.type === actionTypes.DEACTIVATE &&
        this.history[action.payload.id].active === false
      ) {
        return action.payload.id
      }
    }
  }

  wrapAction(action) {
    this.actionIndex++
    return {
      id: uuid(),
      originId: this.originId,
      documentId: this.documentId,
      type: this.type,
      clientTimestamp: this.getTimestamp(),
      clientIndex: this.actionIndex,
      active: true,
      payload: action,
    }
  }

  getTimestamp() {
    return Date.now()
  }

  addAction(action, internal = false) {
    if (internal || this.reducerFunctions[action.type]) {
      this.addWrappedActions([this.wrapAction(action)], true)
      return true
    }
    return false
  }

  addWrappedActions(actions = [], isLocal = false) {
    actions
      .filter((action) => !this.history[action.id])
      .forEach((action) => (this.history[action.id] = action))
    this.emit(isLocal ? 'local-update' : 'remote-update')
  }

  getChronologicalStack() {
    const now = this.getTimestamp()
    return Object.values(this.history).sort((a, b) => {
      const at = a.clientTimestamp + (a.timestampOffset || 0)
      const bt = b.clientTimestamp + (b.timestampOffset || 0)
      return at < bt || (at === bt && a.clientIndex < b.clientIndex) ? -1 : 1
    })
  }

  project() {
    const stack = this.getChronologicalStack()

    // update de/activations
    stack
      .filter((action) =>
        [actionTypes.ACTIVATE, actionTypes.DEACTIVATE].includes(
          action.payload.type
        )
      )
      .forEach(
        (action) =>
          (this.history[action.payload.id].active =
            action.payload.type !== actionTypes.DEACTIVATE)
      )

    // apply actions
    return stack
      .filter(
        (action) =>
          action.active &&
          ![actionTypes.ACTIVATE, actionTypes.DEACTIVATE].includes(
            action.payload.type
          )
      )
      .reduce(
        (prev, action) => this.applyAction(prev, action.payload, false),
        this.initialState
      )
  }

  applyAction(state, action) {
    const { type, ...params } = action
    if (!this.reducerFunctions[type]) {
      return state
    }
    const args = this.parameterNames[type].reduce(
      (prev, name) => [...prev, params[name]],
      [state]
    )
    return this.reducerFunctions[type](...args, ...this.getReducerArguments())
  }

  getReducer() {
    return (state = this.initialState, action) => {
      // apply actions for this specific document only
      if (action._documentId && action._documentId !== this.documentId) {
        return state
      }

      switch (action.type) {
        case actionTypes.EXTERNAL_UPDATE:
          return this.project()
        case actionTypes.ACTIVATE:
        case actionTypes.DEACTIVATE:
          this.addAction(action, true)
          return this.project()
        default:
          return this.addAction(action)
            ? this.applyAction(state, action)
            : state
      }
    }
  }

  getHistoryReducer() {
    return (state = { canUndo: false, canRedo: false }, action) => {
      const stack = this.getUndoRedoStack()
      return {
        canUndo: !!this.findUndoActionId(stack),
        canRedo: !!this.findRedoActionId(stack),
      }
    }
  }
}
